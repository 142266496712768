<template>
  <div class="timetable">
    <div class="time-b">
      <el-breadcrumb class="crumb flexSt" separator="/">
        <el-breadcrumb-item class="flexSt" :to="{ path: '/index' }">
          <div class="ad">
            <i class="iconfont icon-antOutline-home"></i>
          </div>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <div class="ad">课堂</div>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="header">
        <!-- <div class="schoolname">{{ school_name || "该账号暂无学校名" }}</div> -->
        <div class="xzdata">
          <span v-show="dijizhou > 0">第{{ dijizhou }}周</span> {{ monday }}月
          {{ year }}年
        </div>
        <div class="qhbtnbox">
          <div class="" @click="changeCount(1), getWeek(count)">
            <img class="left" src="../../assets//home/路径 46506.png" alt="" />
          </div>
          <div class="" @click="changeCount(-1), getWeek(count)">
            <img class="left" src="../../assets//home/路径 46505.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btnbox">
      <div class="btncreat" @click="addclass(0)">
        <img class="btnjia" src="../../assets/jia.png" alt="" />创建课表
      </div>
      <div class="btncreat" @click="addclass(1)">
        <img class="btnjia" src="../../assets/jia.png" alt="" />编辑课表
      </div>
      <div class="btncreat" @click="addpreless">
        <img class="btnjia" src="../../assets/jia.png" alt="" />创建备课
      </div>
      <div class="btncreat" @click="handleSchedule">
        <img class="btnjia" src="../../assets/jia.png" alt="" />作息时间管理
      </div>
    </div>
    <div v-if="!isShowSchedule" class="timetable-b w100">
      <table class="timetable-content w100">
        <thead>
          <tr>
            <th class="thone"></th>

            <th class="riqi" v-for="(i, j) in datatablelist" :key="j">
              <!-- <div class="zhouqi">{{ i.datas }}</div>
                <div class="rili">{{ i.calendar }}</div> -->
              {{ i.datas }}<br />
              {{ i.calendar }}
            </th>
            <!-- <th v-for="(item1, index1) in weeks" :key="index1">
              {{ "周" + numberToChinease(item1 + 1, "week") }}
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item2, index2) in coursestatrtime" :key="index2">
            <td class="tdtow">
              <p>
                {{
                  item2.course_type == 2
                    ? "大课间"
                    : "第" + item2.class_num + "节"
                }}
              </p>
              <p>
                {{ shangkeData(index2).start_time }}-{{
                  shangkeData(index2).end_time
                }}
              </p>
            </td>
            <template>
              <td v-for="(item3, index3) in 7" :key="index3">
                <div
                  v-if="item2.course_type == 1"
                  @click="
                    changekebiao(index3, index2, showData(index3, index2))
                  "
                  class="dmsjandjs-b"
                  :style="[
                    {
                      background:
                        showData(index3, index2).id != null
                          ? showData(index3, index2).is_prepared == 0
                            ? '#BBBBBB'
                            : showData(index3, index2).is_prepared == 1
                            ? '#74c23d'
                            : '#fff'
                          : '#fff',
                    },
                    { color: '#fff' },
                    { height: '100%' },
                    { width: '100%' },
                  ]">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      padding: 0px 10px 0px 10px;
                    ">
                    <div class="banji">
                      {{ showData(index3, index2).class_name }}
                    </div>
                    <div
                      class="beike"
                      v-if="showData(index3, index2).is_prepared == 0">
                      未备课
                    </div>
                    <div
                      class="beike"
                      v-else-if="showData(index3, index2).is_prepared == 1">
                      已备课
                    </div>
                  </div>
                  <div
                    class="tagclss"
                    v-if="showData(index3, index2).prepare_name != null">
                    备课名：{{ showData(index3, index2).prepare_name }}
                  </div>
                  <div
                    class="tagclss"
                    v-if="showData(index3, index2).project_name != ''">
                    {{ showData(index3, index2).project_name }}
                  </div>
                  <!-- <p>sss   is_prepared</p> -->
                </div>

                <div
                  class="dakejian flexSc"
                  v-if="
                    item2.course_type == 2 &&
                    kejieshulist[index3][index2].start_day
                  ">
                  大课间
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <el-dialog
      v-loading="false"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="63%">
      <el-form
        :model="query"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm">
        <el-form-item :label="'第' + dijizhou + '周'" v-if="is_show">
          <el-input
            v-model="dijizhou"
            type="number"
            laceholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="学期" prop="semester_id">
          <!-- <el-input v-model="query.semester_id" type="number" disabled  ></el-input> -->

          <el-select
            v-model="query.semester_id"
            @change="changegrade"
            placeholder="请选择学期">
            <el-option
              v-for="item in gradelist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带课班级">
          <el-select
            v-model="aaaaaa"
            clearable
            placeholder="请选择班级"
            @change="changeclass">
            <el-option
              v-for="item in classlist"
              :key="item.id"
              :label="item.class_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="季时">
          <el-select
            v-model="time_table_type"
            placeholder="请选择季时"
            @change="changetime_table">
            <el-option
              v-for="item in time_table_typelist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="体育课课表" prop="course">
          <input type="text" v-model="query.course" style="display: none" />
          <div class="weekbox" style="">
            <div class="weeks" style="" v-for="(i, l) in kejieshulist" :key="l">
              {{ weekname[l] }}
              <!-- v-for="(item, z) in i"  kechenlist.indexOf(`${z}${l}`) != -1 && -->
              <div>
                <div
                  class="lesson"
                  style=""
                  :class="{
                    kebiai: item.course_type != 2 && item.class_name,
                  }"
                  v-for="(item, z) in i"
                  :key="z"
                  @click="xuanzekc(z, l, showData(z, l), item)">
                  <div
                    :class="{
                      classidnull:
                        item.class_name == null ||
                        item.class_name == '' ||
                        item.course_type == 2,
                    }">
                    <!-- z + 1 -->
                    <!-- {{ kechenlist.indexOf(`${z}${l}`) != -1 }} -->
                    {{
                      item.course_type == 2
                        ? "大课间"
                        : "第" + item.class_num + "课"
                    }}
                  </div>

                  <!-- <span>{{ item.class_name }}</span> -->
                  <div v-if="item.course_type != 2">{{ item.class_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="Whichlesson" style="" v-for="(i, l) in kejieshulist" :key="l">
            <div class="lesson" style="" :class="{ kebiai: kechenlist.indexOf(`${z}${l}`) != -1 }" v-for="(item, z) in i.zhoukelist"
              :key="z" @click="xuanzekc(z, l)">
              第{{ l + 1 }}课 <br>
              <span>{{ item.class_id }}</span>
            </div>
          </div> -->
        </el-form-item>
        <div class="btnfrom">
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button
            :loading="isSubit"
            v-if="!is_show"
            type="primary"
            @click="submitForm('ruleForm')"
            >保存</el-button
          >
          <el-button
            :loading="isSubit"
            v-else
            type="primary"
            @click="bianjiForm('ruleForm')"
            >确认</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <resttime v-if="isShowSchedule" ref="resttime"></resttime>

    <prepareLessons
      ref="prepare"
      :datatablelist="datatablelist"
      :kejieshulist="kejieshulist"
      :beikename="beikename"
      :coursestatrtime="coursestatrtime"
      :beikeid="beikeid"
      :querydata="queryschedule" />
  </div>
</template>

<script>
import prepareLessons from "./components/prepareLessons.vue";
import resttime from "./components/resttime.vue";
import moment from "moment";
import { weekCourse, colorList } from "./Timetable";
import {
  save,
  Schedule,
  getGrade,
  Class,
  courseweek,
  kbedit,
  coursetable,
} from "../../api/classroon/index";
import { semester } from "../../api/classroon/xueqi";
export default {
  components: {
    prepareLessons,
    resttime,
  },

  data() {
    return {
      subStatus: 0,
      semesterQuerylist: [],
      semesterQuery: {},
      time_table_type: 1,
      time_table_typelist: [
        {
          name: "夏季时",
          id: 1,
        },
        {
          name: "冬季时",
          id: 2,
        },
      ],
      weekname: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      isShowSchedule: false,
      isSubit: false,
      Classstatus: "",
      zhouji: -1,
      dijijie: -1,
      title: "",
      aaaaaa: "",
      query: {
        semester_id: "",
        school_id: "",
        burea_id: "",
        course: [],
      },

      kejieshulist: [
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],
        // [{}, {}, {}, {}, {}, {}, {}, {}],

        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ],
      gradelist: [{}],
      datatablelist: [
        {
          mondays: "",
          datas: "周一",
          calendar: "",
        },
        {
          mondays: "",
          datas: "周二",
          calendar: 21,
        },
        {
          mondays: "",
          datas: "周三",
          calendar: 22,
        },
        {
          mondays: "",
          datas: "周四",
          calendar: 23,
        },
        {
          mondays: "",
          datas: "周五",
          calendar: 24,
        },
        {
          mondays: "",
          datas: "周六",
          calendar: 25,
        },
        {
          mondays: "",
          datas: "周日",
          calendar: 27,
        },
      ],
      classlist: [],
      queryschedule: {
        start_time: "",
        end_time: "",
      },
      kechenlist: [],
      dialogVisible: false,
      ruleForm: {
        grade: "",
        classbj: "",
      },
      rules: {
        // semester_id: [{ required: true, message: '请选择学期', trigger: 'change' }],
        aaaaaa: [{ required: true, message: "请选择班级", trigger: "change" }],
        // course: [{ required: true, message: "请选择课程", trigger: "change" }],
      },
      year: "",
      monday: "",
      user: {},
      zhou: 1,
      queryclass: {
        user_id: 67,
        grade_id: "",
      },
      resourceslist: [],
      startTime: "2022.10.17",
      endTime: "2022.10.23",
      colorList: [], //随机颜色
      weekCourse: [], // 课程详细课程、数量
      coursestatrtime: [], //上课开始时间和结束时间
      weeks: [], //头部周期
      maxCourseLength: 0, //最大课节数,
      count: 0, //上周、下周、本周选择器flag,
      tags: "",
      classid: "",
      classname: "",
      is_show: false,
      queryres: {
        type: 2,
        name: "",
      },
      status: "", // 判断是否已经有课程 0 没有 1 有
      queryweek: {
        start_time: "",
        end_time: "",
      },
      queryedit: {
        start_time: "",
        end_time: "",
        course: [],
      },
      duibi: [],
      dijizhou: 0,
      beikename: "",
      beikeid: 0,
      arr: [
        {
          name: "zhangsan",
          id: 1,
          bb: "2",
        },
        {
          name: "wangwu",
          id: 2,
          bb: "3",
        },
        {
          name: "lisi",
          id: 3,
          bb: "4",
        },
      ],
      school_name: "",
    };
  },
  created() {
    if (this.$route.query.status) {
      console.log(this.$route, "=this.$route");
      this.Classstatus = this.$route.query.status;
      let start_time = this.$route.query.start_time;
      let end_time = this.$route.query.end_time;
      let count = this.$route.query.count;
      this.count = Number(count);
      let item = JSON.parse(this.$route.query.item);
      if (this.Classstatus == 0) {
        if (item.is_prepared == 1) {
          this.$nextTick(() => {
            this.changekebiao(
              item.class_index - 1,
              Number(item.start_day) - 1,
              item
            );
          });
        } else {
          this.$nextTick(() => {
            this.addpreless();
          });
        }
      }
      this.getWeek(count);
    } else {
      this.getWeek(0);
    }
    // this.getClass()
    // this.getweeks()
    this.getsemester();
    // this.getclassSchedule()
    this.colorList = colorList;
    this.sortData();
    this.init();
    // this.getWeek(0);
    this.user = JSON.parse(localStorage.getItem("user"));
    this.school_name = this.user.school_name;
    console.log("user:", this.user);
    this.query.school_id = this.user.school_id;
    this.query.burea_id = this.user.burea_id;

    // this.queryschedule.school_id = this.user.school_id
    // this.queryschedule.burea_id =this.user.burea_id
  },
  methods: {
    editclass() {},
    // 作息时间管理
    handleSchedule() {
      this.isShowSchedule = true;
    },
    //获取归属学校学期数
    getsemester() {
      semester().then((res) => {
        // for (let gra of res.data) {
        //   if (gra.is_enable == 1) {
        //     this.gradelist[0].id = gra.id;
        //     this.query.semester_id = gra.id;
        //     this.gradelist[0].name =
        //       gra.semester_year + "年，第" + gra.semester_season + "学期";
        //   } else {
        //     this.gradelist[0].id = gra.id;
        //     this.query.semester_id = gra.id;
        //     this.gradelist[0].name =
        //       gra.semester_year + "年，第" + gra.semester_season + "学期";
        //   }

        // }

        let list = [];
        this.gradelist = [];
        res.data.forEach((item) => {
          if (item.is_enable == 1) {
            list.push(item);
          }
          this.gradelist.push({
            name: item.semester_year + "年，第" + item.semester_season + "学期",
            id: item.id,
          });

          this.semesterQuerylist = res.data;
          this.semesterQuery = res.data[0];
          console.log(this.semesterQuerylist, "===semesterQuerylist");
        });
        if (list.length > 0) {
          // this.gradelist[0].id = list[0].id;
          this.query.semester_id = list[0].id;
          // this.gradelist[0].name =
          //   list[0].semester_year + "年，第" + list[0].semester_season + "学期";
        } else {
          // this.gradelist[0].id = res.data[0].id;
          this.query.semester_id = res.data[0].id;
          // this.gradelist[0].name =
          //   res.data[0].semester_year +
          //   "年，第" +
          //   res.data[0].semester_season +
          //   "学期";
        }

        console.log("学期数据：", this.gradelist);
      });
    },
    changeclass(e) {
      this.classid = e;
      for (let i of this.classlist) {
        if (this.classid == i.id) {
          this.classname = i.class_name;
          console.log(this.classname);
        }
      }
      console.log(e);
    },
    //获取周数
    // getweeks(){
    //   courseweek(this.queryweek).then(r=>{
    //     this.dijizhou = r.data.week
    //     console.log('第几周：',r.data.week);
    //   })
    // },
    changegrade(val) {
      console.log(val, "ssssssssssssssssssss");
      this.semesterQuerylist.forEach((item) => {
        if (item.id == val) {
          this.semesterQuery = item;
        }
      });
      this.queryclass.grade_id = val;
      this.handleGetcoursetable();
      this.getClass();
    },
    //获取年级数据
    // getgradeClass(){
    //   getGrade({
    //     user_id : this.user.id
    //   }).then(res=>{
    //     console.log(res.data,'res.data');
    //     this.gradelist = res.data
    //   })
    // },
    //获取班级数据
    getClass() {
      Class({
        user_id: this.user.id,
      }).then((res) => {
        this.classlist = res.data;
        console.log(res.data, "class");
      });
    },
    getclassSchedule() {
      Schedule(this.queryschedule).then((res) => {
        this.weekCourse = res.data.data;
        this.dijizhou = res.data.week;
        // this.status = res.data.status;

        // if (res.data.status == 1) {
        this.kejieshulist = res.data.data;
        // }
        this.coursestatrtime = res.data.time;
        // console.log(this.kejieshulist, "====此时的kejieshulist");
        // console.log(res.data.data, "====此时的res.data.data");
        // 大课间自动备课
        let class_num = 0;
        let class_index = 0;
        this.coursestatrtime.forEach((item, index) => {
          class_index++;
          if (item.course_type == 2) {
            item.class_num = null;
            this.kejieshulist.forEach((a, b) => {
              a[index].course_type = 2;
              // a[index].class_index = null;
              a[index].class_index = class_index;
            });
          } else {
            class_num++;
            item.class_num = class_num;
            this.kejieshulist.forEach((a, b) => {
              a[index].course_type = 1;
              // a[index].class_index = item.class_index;
              a[index].class_index = class_index;
              a[index].class_num = item.class_num;
            });
          }
        });

        console.log(this.kejieshulist, "====kejieshulist课表数据");
      });
    },
    xuanzekc(z, l, j, item) {
      // console.log("选中的值", z, l, j);
      // console.log(this.kejieshulist, "aaaaaa");
      if (!this.aaaaaa) {
        this.$message({
          message: "请选择班级",
        });
      } else if (this.kechenlist.indexOf(`${z}${l}`) != -1) {
        this.kechenlist.splice(this.kechenlist.indexOf(`${z}${l}`), 1);
        this.kejieshulist[l][z].class_name = "";
        this.indexs = this.query.course.findIndex(
          (obj) => obj.start_day == l + 1
          // && obj.class_index == z + 1
        );
        this.indexx = this.queryedit.course.findIndex(
          (obj) => obj.start_day == l + 1
          // && obj.class_index == z + 1
        );
        // console.log(this.indexs, "index");
        this.query.course.splice(this.indexs, 1);

        this.queryedit.course.splice(this.indexx, 1);
        // console.log("删除后course的值：", this.query.course);
        // console.log("删除后course的值：", this.query.queryedit);
      } else {
        if (item.course_type == 2) {
          return this.$message({
            message: "大课间不能备课",
          });
        }
        this.kechenlist.push(`${z}${l}`);
        this.kechenlist = [...new Set(this.kechenlist)];
        this.query.course.push({
          // class_index: z + 1,
          class_index: item.class_index,
          start_day: l + 1,
          class_id: this.classid,
          class_num: item.class_num,
        });
        this.queryedit.course.push({
          class_index: item.class_index,
          // class_index: z + 1,
          start_day: l + 1,
          class_id: this.classid,
          class_num: item.class_num,
        });
        this.kejieshulist[l][z].class_name = this.classname;
        this.kejieshulist[l][z].class_id = this.aaaaaa;
      }

      // console.log(this.kejieshulist, "=添加之后的 this.kejieshulist");
      // console.log(this.queryedit, "=添加之后的 this.queryedit");

      // this.aaaaa = this.kechenlist.map(res=>{
      //   return res.dijijie
      // })
      // console.log(this.aaaaa[q],'kechenlist');

      this.zhouji = z;
      this.dijijie = l;
      // console.log('周几', this.zhouji, '第几节课', this.dijijie);
    },
    submitForm(formName) {
      console.log("query的值", this.query);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isSubit) {
            this.$message.warning("请勿重复提交");
          }
          if (this.subStatus == 0) {
            this.$message.error("已有课表");
            return false;
          }
          this.isSubit = true;
          this.query.time_table_type = this.time_table_type;
          save(this.query)
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.handleClose();
              this.getclassSchedule();
            })
            .catch((err) => {
              this.isSubit = false;
            });
          // alert('submit!');
        } else {
          this.$message.error("提交失败");
          return false;
        }
      });
    },
    async bianjiForm(formName) {
      // console.log("query的值", this.query);
      console.log("queryedit的值", this.queryedit);
      // console.log("duibi", this.duibi.length);
      let list = [];
      this.kejieshulist.forEach((item, index) => {
        item.forEach((i) => {
          if (i.course_type != 2 && i.class_name) {
            i.start_day = index + 1;
            if (!i.class_id) {
              i.class_id = this.aaaaaa;
            }
            list.push(i);
          }
        });
      });
      if (this.isSubit) {
        this.$message.warning("请勿重复提交");
      }
      this.isSubit = true;
      let data = {
        course: list,
        end_time: this.queryedit.end_time,
        start_time: this.queryedit.start_time,
        time_table_type: this.time_table_type,
      };
      // this.queryedit
      await kbedit(data)
        .then((res) => {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.handleClose();
          this.getclassSchedule();
        })
        .catch((err) => {
          this.isSubit = false;
        });

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {

      //     // alert('submit!');
      //   } else {
      //     this.$message.error('提交失败');
      //     return false;
      //   }
      // });
    },
    resetForm(formName) {
      this.ruleForm = {};
      (this.zhouji = -1), (this.dijijie = -1), (this.query.grade = "");
      this.aaaaaa = "";
      this.query.course = [];
      this.queryedit.course = [];
      (this.kechenlist = []), (this.dialogVisible = false);
      for (let k of this.kejieshulist) {
        for (let o of k) {
          o.class_name = "";
        }
      }
      this.getclassSchedule();
    },
    handleClose() {
      this.ruleForm = {};
      (this.zhouji = -1), (this.dijijie = -1), (this.query.course = []);
      this.queryedit.course = [];
      this.query.grade = "";
      this.aaaaaa = "";
      (this.kechenlist = []), (this.dialogVisible = false);
      for (let k of this.kejieshulist) {
        for (let o of k) {
          o.class_name = "";
        }
      }
      this.isSubit = false;
      this.getclassSchedule();
    },
    //
    changekebiao(i, j, z) {
      // console.log(z, "数据======");
      // if (z.is_prepared == 0) {
      //   this.$refs.prepare.changevistats()
      //   this.$refs.prepare.getclassSchedule()
      // }
      // if (z == false) {
      //   this.dialogVisible = true
      //   this.kechenlist.push(`${i}${j}`)
      //   this.query.course.push({ 'class_index': i + 1, 'start_day': j + 1 })
      // }
      if (z.is_prepared == 1) {
        this.beikename = "编辑备课";
        this.beikeid = z.prepare_id;
        this.$refs.prepare.getDetails(z.prepare_id);
        this.$refs.prepare.ruleForm.schedule_id.push(z.id);
        this.$refs.prepare.device.push({
          schedule_id: z.id,
          class_name: z.class_name,
          class_index: z.class_index,
          start_day: z.start_day,
        });
        this.$refs.prepare.changevistats();
        this.$refs.prepare.getResources();
        this.$refs.prepare.getclassSchedule();
      }
      // console.log(i, j, z, "选中数据");
      // console.log(this.query.course, "this.query.course");
    },
    // 获取创建课表
    handleGetcoursetable() {
      this.kejieshulist = [[], [], [], [], [], [], []];
      let data = {
        start_time: this.semesterQuery.semester_start_time,
        end_time: this.semesterQuery.semester_end_time,
        time_table_type: this.time_table_type,
      };
      coursetable(data).then((res) => {
        console.log(res.data, "空课表");
        this.kejieshulist = res.data.data;
        let class_num = 0;
        let class_index = 0;
        // 是否可以创建课表 0 不可
        this.subStatus = res.data.status;
        res.data.time.forEach((item, index) => {
          class_index++;
          if (item.course_type == 2) {
            item.class_num = null;
            this.kejieshulist.forEach((a, b) => {
              a[index].course_type = 2;
              // a[index].class_index = null;
              a[index].class_index = class_index;
            });
          } else {
            class_num++;
            item.class_num = class_num;
            this.kejieshulist.forEach((a, b) => {
              a[index].course_type = 1;
              // a[index].class_index = item.class_index;
              a[index].class_index = class_index;
              a[index].class_num = item.class_num;
            });
          }
        });
      });
    },
    // 修改季时
    changetime_table() {
      this.handleGetcoursetable();
    },
    addclass(status) {
      this.isShowSchedule = false;

      this.getClass();
      this.dialogVisible = true;
      if (status == 0) {
        this.is_show = false;
        this.title = "创建课表";
        this.handleGetcoursetable();
      } else if (status == 1) {
        this.getclassSchedule();
        this.is_show = true;
        this.title = "编辑课表";
        this.queryedit.start_time = this.queryschedule.start_time;
        this.queryedit.end_time = this.queryschedule.end_time;
        for (let q of this.kejieshulist) {
          for (let e of q) {
            if (e.id != undefined) {
              if (e.course_type == 1) {
                this.queryedit.course.push({
                  class_index: e.class_index,
                  start_day: e.start_day,
                  class_id: e.class_id,
                  id: e.id,
                  class_num: e.class_num,
                });
                this.duibi.push({
                  class_index: e.class_index,
                  start_day: e.start_day,
                  class_id: e.class_id,
                  id: e.id,
                  class_num: e.class_num,
                });
                this.query.course.push({
                  class_index: e.class_index,
                  start_day: e.start_day,
                  class_id: e.class_id,
                  class_num: e.class_num,
                });
                this.kechenlist.push(`${e.class_index - 1}${e.start_day - 1}`);
              }
            }
          }
        }

        // console.log(this.kejieshulist, "========kejieshulist");
      }
    },
    addpreless() {
      this.isShowSchedule = false;
      this.beikeid = 0;
      this.beikename = "创建备课";
      // console.log(this.beikename, "==beikename");
      this.$refs.prepare.changevistats();
      this.$refs.prepare.getResources();
      this.$refs.prepare.getclassSchedule();
    },
    //改变选择器次数
    changeCount(i) {
      // console.log(this.count, "=================this.count");
      if (i < 0) {
        this.zhou += 1;
      }
      if (i > 0 && this.zhou > 0) {
        this.zhou -= 1;
      }
      // console.log(this.zhou, "zzzzzzzzz");
      this.count += i;
      // console.log(this.count, " this.count");
      return this.count;
    },
    // 排序周期和课数
    sortData() {
      //周期
      this.weekCourse.sort((a, b) => {
        return a.week - b.week;
      });
      this.weekCourse.forEach((item) => {
        for (const key in item) {
          if (key === "courses") {
            item[key].sort((a, b) => {
              return a.index - b.index;
            });
          }
        }
      });
    },
    // 初始化课数(courses)与天数(week)
    init() {
      this.weeks = []; //周集合
      this.maxCourseLength = 0;
      this.weeks = this.weekCourse.map((item) => {
        for (const key in item) {
          if (key === "courses") {
            let max = 0; //
            //取出一周中最大的课节数及当天的最大课节数
            for (let j of item[key]) {
              j.index > this.maxCourseLength &&
                (this.maxCourseLength = j.index); //取所有一周里最大课节值
              j.index > max && (max = j.index); //取当天最大课节值
            }
            // console.log("max:", max);

            //如果当天的课节总数小于当天的最大课节值
            if (item[key].length < max) {
              //以最大课节值为终点遍历当天课节
              for (let i = 0; i < max; i++) {
                //如果下标课节不存在或着与循环的下标不匹配
                if (!item[key][i] || item[key][i].index != i + 1) {
                  item[key].splice(i, 0, " "); //填充空课节
                }
              }
            }
          }
        }
        return item.week;
      });
    },

    /**
     * 处理数据
     * @param { Number } weekIndex 周几对应的下标
     * @param { Number }  courseNum  第几节课对应的下标
     * @returns { String }   返回对应字符
     */
    //  &&
    //     this.weekCourse[weekIndex][courseNum].class_index === courseNum + 1
    showData(weekIndex, courseNum) {
      if (this.weekCourse[weekIndex] && this.weekCourse[weekIndex][courseNum]) {
        // this.getRandomColor();
        return this.weekCourse[weekIndex][courseNum];
      }
      return false;
    },
    // &&
    //     this.weekCourse[weekIndex][courseNum].class_index === courseNum + 1
    showtags(weekIndex, courseNum) {
      if (this.weekCourse[weekIndex] && this.weekCourse[weekIndex][courseNum]) {
        // this.getRandomColor();
        return (this.tags =
          this.weekCourse[weekIndex][courseNum].project_name.join(","));
      }
      return false;
    },

    shangkeData(courseNum) {
      // &&
      //   this.coursestatrtime[courseNum].class_index === courseNum + 1
      if (this.coursestatrtime[courseNum]) {
        return this.coursestatrtime[courseNum];
      }
      return false;
    },
    /**
     * 数字转中文
     * @param { Number } n 需转换的数字
     * @param { Boolean }  identifier  标识符
     * @returns { String }  identifier  转换后的中文
     */
    numberToChinease(n, identifier) {
      const chnArr = ["零", "一", "二", "三", "四", "五", "六", "七"];
      return identifier === "week" && (n === 0 || n === 7) ? "日" : chnArr[n];
    },

    //随机获取颜色
    getRandomColor() {
      let colorList = this.colorList;
      let colorRandom = Math.floor(Math.random() * colorList.length + 1) - 1;
      let color;
      for (let i = 0; colorList.length > i; i++) {
        if (i == colorRandom) {
          color = colorList[i];
        }
      }
      return color;
    },

    //随机上、本、下周 日期
    getWeek1(i, start_time, end_time) {
      let now;

      if (start_time) {
        now = new Date(start_time);
        console.log("有时间");
      } else {
        now = new Date();
      }
      console.log(now, "===now");
      let dqrq = now.getDate();
      console.log(dqrq, "====================dqrq");
      console.log(moment(start_time), "===========moment()");

      this.dtday = dqrq;
      let weekOfDay;
      let momentStart1;
      if (start_time) {
        console.log(2222);
        momentStart1 = moment(now);
      } else {
        console.log(1111);
        momentStart1 = moment();
      }
      console.log(
        momentStart1,
        "========================momentStart",
        start_time
      );
      // return;
      weekOfDay = parseInt(momentStart1.format("E")); //计算今天是这周第几天
      for (let index = 0; index < 7; index++) {
        let last_monday = momentStart1
          .subtract(weekOfDay + 7 * i - (index + 1), "days")
          .format("YYYY-MM-DD");

        console.log(last_monday, "===============================last_monday");
        this.datatablelist[index].calendar = last_monday.slice(8, 10);
        this.datatablelist[index].mondays = last_monday.slice(5, 7);
      }
      //周一日期
      let last_monday = momentStart1
        .subtract(weekOfDay + 7 * i - 1, "days")
        .format("YYYY-MM-DD");
      this.monday = last_monday.slice(5, 7);
      this.months = parseInt(this.monday);
      console.log(this.months, "类型判断");
      this.year = last_monday.slice(0, 4);
      this.xxxxxx = this.dtday + 1;

      let last_sunday = momentStart1
        .subtract(weekOfDay + 7 * (i - 1), "days")
        .format("YYYY-MM-DD"); //周日日期
      this.queryschedule.start_time = last_monday;
      this.queryschedule.end_time = last_sunday;
      console.log(
        this.queryschedule.start_time,
        "-",
        this.queryschedule.end_time
      );
      this.datatablelist[6].calendar = last_sunday.slice(8, 10);
      //调用api获取当前是第几周
      this.getclassSchedule();
    },

    getWeek(i) {
      let now = new Date();
      let dqrq = now.getDate();
      this.dtday = dqrq;
      let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
      for (let index = 0; index < 7; index++) {
        let last_monday = moment()
          .subtract(weekOfDay + 7 * i - (index + 1), "days")
          .format("YYYY-MM-DD");
        this.datatablelist[index].calendar = last_monday.slice(8, 10);
        this.datatablelist[index].mondays = last_monday.slice(5, 7);
      }
      //周一日期
      let last_monday = moment()
        .subtract(weekOfDay + 7 * i - 1, "days")
        .format("YYYY-MM-DD");
      this.monday = last_monday.slice(5, 7);
      this.months = parseInt(this.monday);
      console.log(this.months, "类型判断");
      this.year = last_monday.slice(0, 4);
      this.xxxxxx = this.dtday + 1;
      let last_sunday = moment()
        .subtract(weekOfDay + 7 * (i - 1), "days")
        .format("YYYY-MM-DD"); //周日日期
      this.queryschedule.start_time = last_monday;
      this.queryschedule.end_time = last_sunday;

      console.log(
        this.queryschedule.start_time,
        "-",
        this.queryschedule.end_time
      );
      this.datatablelist[6].calendar = last_sunday.slice(8, 10);
      //调用api获取当前是第几周
      this.getclassSchedule();
    },
  },
};
</script>

<style scoped lang="less">
.crumb {
  // line-height: 75px;
  font-size: 18px;
  margin-left: 43px;

  // background-color: pink;
}
// .ad {
//   color: #112bb4;
//   font-weight: bold;
//   i {
//     font-size: 28px;
//   }
//   // color: #101010 !important;
// }
.weekbox {
  display: flex;
  justify-content: space-between;
  padding: 0px 75px 0px 0px;

  .weeks {
    width: 137px;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(226, 216, 216);
    .lesson {
      text-align: center;
      width: 137px;
      height: 70px;
      border-top: 1px solid rgb(226, 216, 216);
      .classidnull {
        line-height: 70px;
      }
    }
  }
}

.Whichlesson {
  display: flex;
  justify-content: space-between;
  padding: 0px 75px 0px 0px;
  cursor: pointer;

  // .lesson {
  //   text-align: center;
  //   width: 137px;
  //   border: 1px solid rgb(226, 216, 216);
  // }
}

.btnfrom {
  width: 100%;
  text-align: center;
}

/deep/ .kebiai {
  background: #ebbba7;
}

.el-form-item__label {
  width: 110px !important;
}

.el-form-item__content {
  text-align: center;
  margin-left: 20px !important;
}

/deep/.el-select {
  width: 90%;
}

/deep/.el-dialog__headerbtn {
  .el-icon-close {
    color: #fff;
  }
}

/deep/.el-dialog__header {
  background: #516cf7;
}

/deep/.el-dialog__title {
  border-left: #ffffff solid 2px;
  color: #fff;
}

.timetable {
  background-color: #f1f7ff;

  .w100 {
    width: 1664px !important;
  }

  .h100 {
    height: 100% !important;
  }

  .btnbox {
    display: flex;
    margin-left: 48px;
    margin-top: 35px;
  }

  .btncreat {
    width: 227px;
    height: 72px;
    background: #516cf7;
    color: aliceblue;
    font-size: 24px;
    text-align: center;
    line-height: 72px;
    border-radius: 15px 15px 15px 15px;
    margin-right: 25px;
    cursor: pointer;

    .btnjia {
      width: 27px;
      height: 27px;
      position: relative;
      top: 5px;
      margin-right: 11px;
    }
  }

  .time-b {
    // margin-bottom: 24px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 36px;
    box-sizing: border-box;
    .header {
      display: flex;

      .schoolname {
        margin-left: 43px;
        width: 364px;
        height: 37px;
        font-size: 28px;
        color: #112bb4;
        font-weight: bold;
      }

      .xzdata {
        margin-left: 862px;
        font-weight: bold;
        color: #112bb4;
        font-size: 28px;
      }

      .qhbtnbox {
        width: 43px;
        margin-left: 42px;
        display: flex;
        padding-top: 9px;
        justify-content: space-between;

        .left {
          width: 11px;
          height: 19px;
        }
      }
    }
  }

  .timetable-b {
    padding: 31px 48px 0px 48px;
    overflow: auto;
    border-radius: 12px 12px 12px 12px;

    .thone {
      width: 140px;
      text-align: center;
    }

    .tdtow {
      background: #f2f7ff;
    }
    .tagclss {
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .timetable-content {
      width: 215px;
      height: 95px;
      table-layout: fixed;
      background-color: #fff;
      border-collapse: collapse; //设置表格的边框是否被合并为一个单一的边框
      text-align: center;
      color: #333333;
      font-weight: 400;
      font-size: 18px;

      thead {
        height: 70px;
        background: #f2f7ff;

        th {
          border: 2px solid rgba(27, 100, 240, 0.1);
        }
      }

      tbody {
        height: calc(100% - 2px) / 7;

        td {
          border: 1px solid #d6d6d6;
          height: 96px;

          .dmsjandjs-b {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .banji {
              width: 137px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .beike {
              width: 85px;
            }
          }
        }
      }
    }
  }
}

.el-dialog {
  width: 1248px;
}

::v-deep {
  .time-controller {
    .el-button-group {
      .el-button {
        height: 46px;
        background: #ffffff;
        font-size: 18px;
        font-weight: 600;
        border: 1px solid rgba(27, 100, 240, 0.1);
        border-radius: 55px;
        color: #1b64f0;
      }

      :nth-child(2) {
        margin: 0px 12px;
      }
    }
  }
}
.dakejian {
  background: #74c23d;
  width: 100%;
  height: 100%;
  color: #fff;
}
</style>
