<template>
  <div>
    <el-dialog
      :title="beikename"
      :visible.sync="openpl"
      width="80%"
      :before-close="handlesClose"
    >
      <div class="dialogbox">
        <div class="leftbox">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item label="课程" prop="schedule_id">
              <el-select v-model="ruleForm.schedule_id" clearable placeholder="教会">
                <el-option label="教会" value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <div class="inputs">
              <el-form-item label="备课名称" prop="prepare_name">
                <el-input
                  v-model="ruleForm.prepare_name"
                  placeholder="请输入备课名称"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="项目" prop="project_id">
              <!-- <el-select @remove-tag="handleremovetag" :value="selected" :class="{ all: optionsAll }" multiple clearable
                filterable placeholder="请选择" :popper-append-to-body="false" @change="handleChangeAelected" ref="sel"
                @focus="handleblurselected">
                <el-option value="0" label="全部标签" class="multiple">
                  <el-checkbox v-model="optionsAll" @change="handleoptionsAllChange">
                    全部标签
                  </el-checkbox>
                </el-option>
                <el-option class="multiple" :value="item.value" :label="item.label" v-for="(item, key) in optionsData"
                  :key="key">
                  <el-checkbox v-model="item.check" @change="handleTaskItemChange(item)">
                    {{ item.label }}
                  </el-checkbox>
                </el-option>
              </el-select> -->
              <el-cascader
                popper-class="last-check"
                v-model="jqflArr"
                :options="Projectslist"
                :show-all-levels="false"
                @change="projectchange"
                ref="myCascader"
                clearable
                :props="{
                  multiple: true,
                  checkStrictly: true,
                  label: 'project_name',
                  value: 'id',
                  children: 'sub',
                }"
              >
                <template slot-scope="{ node, data }">
                  <span style="display: block" @click="onClick(node)">{{
                    data.project_name
                  }}</span>
                </template>
              </el-cascader>
            </el-form-item>

            <el-form-item label="备课课表" prop="schedule_id">
              <input
                type="text"
                v-model="ruleForm.schedule_id"
                style="display: none"
              />
              <div class="weekbox" style="">
                <div
                  class="weeks"
                  style=""
                  v-for="(item, index) in datatablelist"
                  :key="index"
                >
                  {{ item.datas }}<br />

                  <div class="riqi">{{ item.calendar }}</div>
                </div>
              </div>
              <!-- 8 -->
              <div
                class="Whichlesson"
                style=""
                v-for="(a, l) in coursestatrtime"
                :key="l"
              >
                <div
                  class="lesson"
                  style=""
                  :class="{ kebiai: kechenlist.indexOf(`${z}${l}`) != -1 }"
                  v-for="(item, z) in 7"
                  :key="z"
                  @click="xuanzekc(z, l, showData(z, l))"
                >
                  <div v-if="a.course_type == 1" style="height: 80px">
                    <div v-if="beikename == '创建备课'" class="banji">
                      <!-- @click.stop="changekebiao(z, l, showData(z, l))" -->
                      <span
                        class="classsize"
                        :class="{
                          preparcolor: showData(z, l).is_prepared == 1,
                        }"
                        >{{ showData(z, l).class_name || "" }}</span
                      >
                      <br /><span v-if="showData(z, l).is_prepared == 0"
                        >未备课</span
                      >
                      <span
                        v-if="showData(z, l).is_prepared == 1"
                        style="color: rgb(78, 190, 190)"
                        >已备课</span
                      >
                    </div>
                    <div
                      :style="{
                        background:
                          showData(z, l).prepare_id == beikeid
                            ? '#EBBBA7'
                            : '#fff',
                      }"
                      v-else
                    >
                      <span
                        v-if="showData(z, l).is_prepared == 1"
                        class="classsize"
                        style="color: rgb(78, 190, 190)"
                        >{{ showData(z, l).class_name || "" }}</span
                      ><br />
                      <span
                        v-if="showData(z, l).is_prepared == 1"
                        style="color: rgb(78, 190, 190)"
                        >已备课</span
                      >
                    </div>
                  </div>

                  <div
                    v-if="a.course_type == 2"
                    style="height: 80px; line-height: 80px"
                  >
                    大课间
                  </div>
                </div>
              </div>
            </el-form-item>
            <!-- </el-form-item> -->
            <!-- <el-form-item label="课堂直播" prop="grade">
              <el-radio-group v-model="radio" @change="selectWhetherPusher">
                <el-radio :label="3">是</el-radio>
                <el-radio :label="6">否</el-radio>
              </el-radio-group>
            </el-form-item> -->

            <el-form-item label="教案">
              <div class="flexSb">
                <el-upload
                  class="upload-demo"
                  :http-request="Upload"
                  drag
                  :headers="headers"
                  :action="action"
                  multiple
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="logohandleRemove"
                  :file-list="filelist"
                  :accept="accept"
                >
                  <div class="el-upload__text">
                    <div>
                      <img
                        class="huixinimg"
                        src="../../../assets/classroon/组 7641.png"
                        alt=""
                      />
                      <span class="tiishiwenzi">点击上传教案</span>
                    </div>
                  </div>
                </el-upload>
                <!-- <shangchuan  /> -->
              </div>
              <div v-show="showProgress">
                <el-button type="primary" sizi="mini" round @click="handleSure"
                  >提交教案</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="资源" prop="resource">
              <div>
                <span
                  v-if="this.$store.state.quoteclass.length == 0"
                  style="cursor: pointer"
                  @click="hanleSel(0)"
                  >请从右侧引入资源</span
                >
                <div
                  class="ziyuanlist flexSb"
                  style="margin-bottom: 4px"
                  @click="openziyuan(item)"
                  v-for="(item, index) in this.$store.state.quoteclass"
                  :key="index"
                >
                  <span>{{ item.name }}</span>
                  <i
                    @click.stop="delziyuan(item, index)"
                    class="el-icon-close"
                  ></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="跟我练">
              <div>
                <span
                  v-if="this.$store.state.genwolian.length == 0"
                  style="cursor: pointer"
                  @click="hanleSel(0)"
                  >请从右侧引入资源</span
                >
                <div
                  class="ziyuanlist flexSb"
                  style="margin-bottom: 4px"
                  @click="openziyuan(item)"
                  v-for="(item, index) in this.$store.state.genwolian"
                  :key="index"
                >
                  <span>{{ item.name }}</span
                  ><i
                    @click.stop="delgenwolian(item, index)"
                    class="el-icon-close"
                  ></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="生成资源包" prop="is_res_pkg">
              <el-radio-group
                v-model="ruleForm.is_res_pkg"
                @change="selectResource"
              >
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="是否共享"
              prop="is_shared"
              v-if="ruleForm.is_res_pkg == 1"
            >
              <el-radio-group v-model="ruleForm.is_shared">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否使用设备" prop="is_device">
              <el-radio-group v-model="ruleForm.is_device">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="设备借用"
              prop="is_devices"
              v-if="ruleForm.is_device == 1"
            >
              <div v-for="(item, index) in device" :key="index">
                {{ item.class_name }}&nbsp;&nbsp;第{{
                  item.start_day
                }}周&nbsp;&nbsp;第{{ item.class_index }}节课<br />
                <!-- <el-select @remove-tag="handleremovetag" :value="selected"  multiple 
                  filterable placeholder="请选择" :popper-append-to-body="false" @change="handleChangeAelected" ref="sel"
                  @focus="handleblurselected">
                  <el-option-group v-for="(group,j) in equipment"
                                        :key="j"
                                        :label="group.device_type_name">
                    <el-option class="multiple" :value="item.device_group_no" :label="item.device_type_name" v-for="(item, key) in group.items"
                      :key="key">
                      <el-checkbox v-model="item.check" @change="handleTaskItemChange(item,group.items)">
                        {{ item.device_type_name }}
                      </el-checkbox>
                    </el-option>
                  </el-option-group>
                </el-select> -->
                <el-select
                  v-model="device[index].value"
                  multiple
                  placeholder="请选择"
                  @focus="handleblurselected(item)"
                >
                  <el-option-group
                    v-for="(group, j) in equipment"
                    :key="j"
                    :label="group.device_type_name"
                  >
                    <el-option
                      class="multiple"
                      :value="item.id"
                      :label="item.device_type_name"
                      v-for="(item, key) in group.items"
                      :key="key"
                    >
                      <!-- <el-checkbox v-model="item.check" @change="handleTaskItemChange(item,group.items)">
                        {{ item.device_type_name }}
                      </el-checkbox> -->
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="rightbox">
          <div class="tabbar">
            <div
              class="screen"
              :class="{ active: active == index }"
              @click="changebabar(index)"
              v-for="(item, index) in tabbarlist"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <!-- <div v-if="active != 2" style="display: flex;">
            <div class="navbar" :class="{ active1: active1 == j }" @click="changenavbar(j)" v-for="(i, j) in navbarlist"
              :key="j">{{ i }}</div>
          </div>-->
          <div v-if="active == 2" style="display: flex">
            <div
              class="navbar"
              :class="{ active1: active2 == j }"
              @click="changenavbar1(j)"
              v-for="(i, j) in navbarlist1"
              :key="j"
            >
              {{ i }}
            </div>
          </div>
          <div class="searchbox">
            <div v-if="active == 0">
              <search @search="search" ref="searchs" @input="input"></search>
            </div>
            <div v-else-if="active == 1">
              <search @search="search1" ref="searchs"></search>
            </div>
            <div v-else>
              <search @search="search2" ref="searchs"></search>
            </div>
            <!-- <div style="width: 170px;">
              <el-select v-model="value" placeholder="公共资源">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div v-if="active == 12">
            <labels :typrojectlist="typrojectlist" />
          </div>
          <resource :resource="resourcelist" :active="active" ref="resource" />
          <!-- <div v-if="active == 0 || active == 1">
            <resource :resource="resourcelist" :active="active" ref="resource" />
          </div>
          <div v-if="active == 2">
            <resource1 :resource="resourcelist" :active="active" ref="resource" />
          </div> -->
          <zhjyPagination
            v-if="active < 2 && totalData != 0"
            :pageNum.sync="page.pageNum"
            :pageSize="page.pageSize"
            :total="totalData"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
          >
          </zhjyPagination>
        </div>
      </div>
      <div class="btnfrom">
        <div>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
        <div class="queren">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </div>
        <!-- <div>
          <el-button @click="submitForm('ruleForm')">预览</el-button>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { baseUrl } from "@/utils/config";
import { client, getFileNameUUID } from "../../../utils/ali-oss/ali-oss";
import search from "../../../components/search.vue";
import labels from "./labels.vue";
import resource from "./resource.vue";
import resource1 from "./resource1.vue";
import shangchuan from "../../../components/shangchuan.vue";
import zhjyPagination from "@/components/zhjyPagination";
import {
  gradeClass,
  preparationSave,
  Resources,
  Schedule,
  Projects,
  upload,
  ResourcesPkgs,
  Device,
  cancellations,
} from "../../../api/classroon/index";
import { number } from "echarts";
export default {
  props: {
    querydata: Object,
    datatablelist: Array,
    coursestatrtime: Array,
    beikename: String,
    beikeid: Number,
    coursestatrtime: Array,
    kejieshulist: Array,
  },
  components: {
    search,
    labels,
    resource,
    shangchuan,
    resource1,
    zhjyPagination,
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getResources();
    this.getclassSchedule();
    this.getProjects();
  },
  computed: {
    selected() {
      if (this.selectedOptions.length === this.options.length) {
        return [];
      } else {
        let data = [];
        if (this.optionsAll) {
          data = ["0"];
        } else {
          data = this.selectedOptions;
        }
        // return this.selectedOptions;
        return data;
      }
    },
  },
  data() {
    return {
      value1: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      fenlei: [
        {
          label: "热门城市",
          // label: '城市名'
        },
      ],
      jqflArr: [],
      xxxxxx: [],
      totalData: 0,
      pjid: "",
      xiabiao: "",
      projectnvalue: "",
      accept: ".docx,.doc,.ppt,.pdf",
      resourcelist: [],
      selectedOptions: [],
      kechenlist: [],
      user: {},
      aaa: {
        rrrrrr: "",
      },
      optionsData: [
        // {
        //   label: '专项训练',
        //   value: '1'
        // },
        // {
        //   label: '一分钟跳绳',
        //   value: '1'
        // },
        // {
        //   label: '篮球',
        //   value: '23'
        // },
        // {
        //   label: '足球',
        //   value: '4'
        // }
      ],
      device: [],
      optionsAll: false,
      course: [], // 存储课表选中数据
      ruleForm: {
        prepare_name: "",
        schedule_id: [], //要备课的课程id
        resource: [],
        prepare_id: "",
        device: [],
        is_shared: 0,
        is_res_pkg: 1,
        is_device: 0,
      },
      node: "",
      Projectslist: [], //项目数据
      queryres: {
        type: 1,
        name: "",
        per_page: 10,
      },
      active: 0,
      active1: 0,
      active2: 0,
      resource: 3,
      radio: 3,
      progress: 0,
      openpl: false,
      showProgress: false,
      projectid: [],
      fileInfo: {},
      filelist: [],
      cancelbk: "",
      resourceid: [], //接受store的数据
      genwolianid: [],
      jiaoanidlist: [], //存储手动上传教案id
      equipment: [],
      project_id: [],
      weekCourse: [],
      tabbarlist: ["资源", "教案", "资源包"],
      navbarlist: ["达标天天练", "精准干预", "优课资源", "单项资源"],
      navbarlist1: ["我的资源包", "共享资源包"],
      typrojectlist: [
        "全部",
        "达标天天练",
        "1分钟跳绳",
        "50x8往返跑",
        "达标天天练",
        "1分钟跳绳",
        "50x8往返跑",
        "达标天天练",
        "1分钟跳绳",
        "50x8往返跑",
      ],
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      action: baseUrl + "/upload",
      value: "",
      kechenlist: [],
      options: [],
      bkForm: {
        grade: "",
      },
      rules: {
        prepare_name: [
          { required: true, message: "备课名称不能为空", trigger: "change" },
        ],
        resource: [
          { required: true, message: "资源不能为空", trigger: "change" },
        ],
        schedule_id: [
          { required: true, message: "请选择备课课程", trigger: "change" },
        ],
        is_res_pkg: [
          {
            required: true,
            message: "是否生成资源包不能为空",
            trigger: "change",
          },
        ],
        is_device: [
          {
            required: true,
            message: "是否使用设备不能为空",
            trigger: "change",
          },
        ],
      },
      queryschedule: {
        start_time: "2023-9-4",
        end_time: "2023-9-10",
      },
      queryRespkgs: {
        name: "",
        type: 1,
      },
      querysb: {
        schedule_id: "",
      },
    };
  },
  methods: {
    hanleSel(i) {
      console.log(1111111111);
      // this.active = 1;
      this.changebabar(i);
    },
    delziyuan(item, index) {
      console.log(this.$store.state.quoteclass);
      this.$store.state.quoteclass.splice(index, 1);
    },
    delgenwolian(item, index) {
      this.$store.state.genwolian.splice(index, 1);
    },
    //资源对比教案包id
    teachingdui() {
      console.log(this.$store.state.ziyuanbaoid, "ziyuanbaoid");
      for (let i of this.resourcelist) {
        for (let j of this.$store.state.ziyuanbaoid) {
          if (j == i.id) {
            this.$set(i, "state", true);
          }
        }
      }
    },
    //资源对比引入资源和教案
    zycomparison() {
      console.log(this.$store.state.quoteid);
      for (let i of this.resourcelist) {
        for (let j of this.$store.state.duibiId1) {
          if (j == i.id) {
            this.$set(i, "state", true);
          }
        }
        for (let j of this.$store.state.duibiId2) {
          if (j == i.id) {
            this.$set(i, "genwolian", true);
          }
        }
      }
    },
    //切换资源分页
    handleCurrentChange(i) {
      this.queryres.page = i;
      this.getResources();
      console.log(i, "分页");
    },
    handleSizeChange() {
      this.queryres.per_page = i;
      this.getResources();
      console.log(i, "tiaoshu");
    },
    openziyuan(i) {
      if (i.file_url != null) {
        window.open(i.file_url);
      }
      console.log(i);
    },
    //筛选联级选择器最后一层数据
    projectchange(any) {
      let onceArray = []; // 每个数组最后一项元素组成的集合
      let newNum = []; // 各元素出现的次数
      let finalArray = []; // 最终集合
      if (any.length > 0) {
        any.forEach((any) => {
          onceArray.push(any[any.length - 1]);
        });
        const num = any.flat();
        const map = new Map();
        for (let i of num) {
          if (!map.has(i)) {
            map.set(i, 1);
          } else {
            map.set(i, map.get(i) + 1);
          }
        }
        newNum = [...map.entries()]
          .filter((it) => it[1] == 1)
          .map((it) => it[0]);

        for (let i = 0; i < onceArray.length; i++) {
          if (newNum.indexOf(onceArray[i]) > -1) {
            finalArray.push(onceArray[i]);
          }
        }
      }
      this.project_id = finalArray;
      console.log(this.project_id, "this.query.Jqfldms");
      console.log(this.jqflArr, "this.jqflArr的值");
    },
    onClick() {},
    //选中的备课数据
    changekebiao(z, j, i, type) {
      console.log(
        "z",
        z,
        "j",
        j,
        "z",
        i,
        "type",
        type,
        "========================"
      );
      if (i && i.is_prepared == 0) {
        if (this.ruleForm.schedule_id.indexOf(i.id) != -1) {
          this.ruleForm.schedule_id.splice(
            this.ruleForm.schedule_id.indexOf(i.id),
            1
          );
          console.log("这是进了删除", this.ruleForm);
          this.device.splice(this.ruleForm.schedule_id.indexOf(i.id), 1);
        } else {
          this.ruleForm.schedule_id.push(i.id);
          this.device.push({
            schedule_id: i.id,
            class_name: i.class_name,
            class_index: i.class_index,
            start_day: i.start_day,
          });

          this.ruleForm.schedule_id = [...new Set(this.ruleForm.schedule_id)];
          console.log("这是进了添加", this.ruleForm);
        }
        console.log(this.ruleForm.schedule_id, "this.ruleForm.schedule_id");
        // console.log(this.device, "设备");
        // console.log("z", z, "j", j, "z", i.id);
      }
    },
    //获取课表数据
    getclassSchedule() {
      Schedule(this.querydata).then((res) => {
        this.weekCourse = res.data.data;
        console.log("课表：", this.weekCourse);
        console.log(this.querydata);
      });
    },
    //获取借用设备
    getDevice() {
      Device(this.querysb).then((res) => {
        this.equipment = res.data;
        for (let i of this.equipment) {
          if (!!i.items) {
            for (let j of i.items) {
              this.$set(j, "id", j.device_group_no + j.device_type);
              console.log(j);
            }
          }
        }
        console.log("借用设备数据：", this.equipment);
      });
    },
    //获取资源包数据
    getResourcesPkgs() {
      ResourcesPkgs(this.queryRespkgs).then((res) => {
        this.resourcelist = res.data;
        for (let i of this.resourcelist) {
          this.$set(i, "state", false);
        }
        this.teachingdui();
        console.log("我的资源包", res);
      });
    },
    //课程排序
    showData(weekIndex, courseNum) {
      if (
        this.weekCourse[weekIndex] &&
        this.weekCourse[weekIndex][courseNum] &&
        this.weekCourse[weekIndex][courseNum].class_index === courseNum + 1
      ) {
        // this.getRandomColor();
        return this.weekCourse[weekIndex][courseNum];
      }
      return false;
    },
    //获取项目数据
    getProjects() {
      Projects().then((res) => {
        this.Projectslist = res.data;

        console.log("项目数据：", this.Projectslist);
      });
    },
    //提交资源
    handleSure() {
      console.log(this.filelist);
      console.log(this.fileInfo.path, "userinfo");
      if (this.fileInfo != undefined && this.fileInfo != null) {
        let data = {
          burea_id: this.user.burea_id,
          school_id: this.user.school_id,
          user_id: this.user.user_id,
          user_show_name: this.user.user_show_name,
          path: this.fileInfo.path,
          // file_url: this.fileInfo.path,
          // file_name: this.fileInfo.name,
          name: this.fileInfo.name,
          file_type: this.fileInfo.file_type,
          // file_suffix: this.fileInfo.mime,
          mime: this.fileInfo.mime,
          name: this.fileInfo.name,
          filesize: this.fileInfo.filesize,
          resource_name: this.fileInfo.name,
          res_lib_type: 2,
        };
        upload(data).then((res) => {
          this.jiaoanidlist.push({ resource_id: res.data.id, type: 1 });
          console.log(res.data.id);
          this.$message.success("上传成功");
          this.fileInfo = {};
          // this.progress=0
          // this.filelist = []
          this.showProgress = false;
        });
      } else {
        this.$message.error("请先上传文件");
      }
    },
    //获取资源数据
    getResources() {
      Resources(this.queryres).then((res) => {
        this.resourcelist = res.data.data;
        this.totalData = res.data.total;
        for (let i of this.resourcelist) {
          let wjtype = i.name.substr(i.name.lastIndexOf(".") + 1);
          // console.log(wjtype);
          this.$set(i, "state", false);
          this.$set(i, "genwolian", false);
          this.$set(i, "houzhui", wjtype);
        }
        this.zycomparison();
        console.log(this.resourcelist, "资源数据");
      });
    },
    //选中课表
    xuanzekc(z, l, i) {
      console.log(i, "iiiiiiiiiiiiiii");
      if (i != false && i.is_prepared == 0) {
        if (this.kechenlist.indexOf(`${z}${l}`) != -1) {
          this.kechenlist.splice(this.kechenlist.indexOf(`${z}${l}`), 1);
          this.course.splice(
            this.course.indexOf({ class_index: l + 1, start_day: z + 1 }, 1)
          );
          if (i.course_type == 1 && this.beikename == "创建备课") {
            this.changekebiao(z, l, i, 1);
            console.log("这是第一种情况");
          }
        } else {
          this.kechenlist.push(`${z}${l}`);
          this.kechenlist = [...new Set(this.kechenlist)];
          this.course.push({ class_index: l + 1, start_day: z + 1 });
          console.log("course的值：", this.course);
          console.log("我选中了：", this.kechenlist);

          if (i.course_type == 1 && this.beikename == "创建备课") {
            this.changekebiao(z, l, i, 2);
            console.log("这是第二种情况");
          }
        }
      } else if (i != false && i.is_prepared == 1) {
        console.log("删除的备课数据", i);
        this.cancelbk = i.id;
        this.getcancellation();
      }

      // this.aaaaa = this.kechenlist.map(res=>{
      //   return res.dijijie
      // })
      // console.log(this.aaaaa[q],'kechenlist');

      this.zhouji = z;
      this.dijijie = l;
      console.log("周几", this.zhouji, "第几节课", this.dijijie);
    },
    //获取备课详情 /api/xx/preparationDetails/{id}
    async getDetails(id) {
      const res = await this.axios.post(`/xx/preparationDetails/${id}`);
      this.ruleForm.prepare_name = res.data.prepare_name;
      this.ruleForm.is_device = res.data.is_device;
      this.ruleForm.is_shared = res.data.is_shared;
      this.ruleForm.is_res_pkg = res.data.is_res_pkg;
      console.log("详情数据", res);
      //获取资源id
      let resourceids = [];
      for (let h of res.data.resource) {
        resourceids.push({ resource_id: h.resource_id, type: h.type });
      }
      //通过获取的资源id对比资源数据，将数据存入容器
      for (let a of this.resourcelist) {
        resourceids.map((s) => {
          if (a.id == s.resource_id) {
            console.log("ffff", s.type);
            this.$refs.resource.addxqshuju(a, s.type);
            this.getResources(); // 调用资源接口，对比容器中的id,将state的值设置true
          }
        });
      }
      this.projectid = res.data.project_id.split(",");
      let jqflarrs = [];
      for (let t of this.Projectslist) {
        if (!!t.sub) {
          for (let q of t.sub) {
            if (!!q.sub) {
              for (let e of q.sub) {
                this.projectid.map((r) => {
                  if (e.id == r) {
                    jqflarrs.push([t.id, q.id, e.id]);
                  }
                });
              }
            }
          }
        }
      }
      this.jqflArr = jqflarrs;
      console.log("备课详情数据：", res.data);
    },
    //取消备课
    getcancellation() {
      this.$confirm("此操作将取消已经备课的课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delbqsj();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async delbqsj() {
      const res = await this.axios.post(`/xx/cancellation/${this.cancelbk}`);
      if (res.code == 1) {
        this.resetForm();
        this.$parent.getclassSchedule();
      }
    },
    resetForm() {
      this.ruleForm.is_device = 0;
      this.ruleForm.is_shared = 0;
      this.ruleForm.is_res_pkg = 1;
      this.active = 0;
      this.ruleForm.project_name = "";
      this.ruleForm.project_id = [];
      this.jqflArr = [];
      this.ruleForm.prepare_name = "";
      this.filelist = [];
      this.openpl = false;
      this.$store.state.quoteclass = [];
      this.$store.state.genwolian = [];
      this.$store.state.ziyuanbaoid = [];
      this.$store.state.cunchuziy = [];
      this.$store.state.ccgenwolian = [];
      this.ruleForm.resource = [];
      this.jiaoanidlist = [];
      this.resourceid = [];
      this.genwolianid = [];
      this.$store.commit("quoteid", this.resourceid);
      this.$store.commit("ccgenwolian", this.genwolianid);
      this.$refs.resource.clearquote();
      this.ruleForm.device = [];
      this.ruleForm.schedule_id = [];
      this.device = [];
      this.kechenlist = [];
      console.log("这是进了重置", this.ruleForm);
    },
    handlesClose() {
      this.ruleForm.is_device = 0;
      this.ruleForm.is_shared = 0;
      this.ruleForm.is_res_pkg = 1;
      this.ruleForm.project_name = "";
      this.ruleForm.project_id = [];
      this.jqflArr = [];
      this.ruleForm.prepare_name = "";
      this.active = 0;
      this.filelist = [];
      this.openpl = false;
      this.$store.state.quoteclass = [];
      this.$store.state.genwolian = [];
      this.$store.state.ziyuanbaoid = [];
      this.$store.state.cunchuziy = [];
      this.$store.state.ccgenwolian = [];
      this.ruleForm.resource = [];
      this.jiaoanidlist = [];
      this.resourceid = [];
      this.genwolianid = [];
      this.$store.commit("quoteid", this.resourceid);
      this.$store.commit("ccgenwolian", this.genwolianid);
      this.$refs.resource.clearquote();
      this.ruleForm.device = [];
      this.ruleForm.schedule_id = [];
      this.device = [];
      this.kechenlist = [];
      console.log("这是进了关闭", this.ruleForm);
    },

    async submitForm(v) {
      let arrlist = [];
      for (let i of this.Projectslist) {
        if (!!i.sub) {
          for (let j of i.sub) {
            if (!!j.sub) {
              for (let k of j.sub) {
                this.project_id.map((r) => {
                  if (k.id == r) {
                    arrlist.push(k.project_name);
                  }
                });
              }
            }
          }
        }
      }
      this.ruleForm.project_id = this.project_id.join(",");
      this.ruleForm.project_name = arrlist.join(",");
      //转存资源id
      this.resourceid = this.$store.state.cunchuziy;
      this.genwolianid = this.$store.state.ccgenwolian;
      this.ruleForm.resource = [
        ...this.resourceid,
        ...this.jiaoanidlist,
        ...this.genwolianid,
      ];
      this.ruleForm.source_type = this.$store.state.source_type;
      if (this.ruleForm.is_device == 1) {
        for (let i of this.device) {
          this.ruleForm.device.push({
            schedule_id: i.schedule_id,
            device_group_no: i.value.map((q) => {
              return q.substring(0, 1);
            }),
            device_type: i.value.map((q) => {
              return q.substring(1, q.length);
            }),
          });
        }
      }
      // console.log('arrlist', arrlist);
      // console.log('jiaoanidlist', this.jiaoanidlist);
      // console.log('resourceid的值：', this.$store.state.quoteid);
      console.log("source_type的值", this.$store.state.source_type);

      console.log("this.value1", this.device);
      this.ruleForm.user_id = this.user.id;
      // this.openpl = false
      console.log("this.ruleForm的数据：", this.ruleForm);
      console.log(this.beikeid, "beikeid");
      // this.resetForm()
      //判断创建还是编辑 >0编辑
      if (this.beikeid == 0) {
        this.$refs[v].validate((valid) => {
          if (valid) {
            preparationSave({
              row: this.ruleForm,
            }).then((res) => {
              if (res.msg == "保存成功") {
                this.$message.success(res.msg);
                this.resetForm();
                this.$parent.getclassSchedule();
                this.$router.go(0);
              }
            });
          } else {
            console.log(this.ruleForm, "= this.ruleForm");
            if (this.ruleForm.prepare_name == "") {
              this.$message.warning("备课名称不能为空");
            } else if (this.ruleForm.schedule_id.length == 0) {
              this.$message.warning("备课课表不能为空");
            } else if (this.ruleForm.resource.length == 0) {
              this.$message.warning("资源不能为空");
            } else if (this.ruleForm.is_res_pkg == "") {
              this.$message.warning("请选择是否共享");
            } else if (this.ruleForm.is_device == "") {
              this.$message.warning("请选择是否使用设备");
            }

            console.log("error submit!!");
            return false;
          }
        });
      }
      if (this.beikeid > 0) {
        if (this.ruleForm.resource != "") {
          const res = await this.axios.post(
            `/xx/preparationUpdate/${this.beikeid}`,
            {
              row: this.ruleForm,
            }
          );
          if (res.msg == "保存成功") {
            this.$message.success(res.msg);
            this.resetForm();
            this.$parent.getclassSchedule();
            this.$router.go(0);
          }
        } else {
          this.$message.error("请引入资源");
        }
      }
    },
    handleremovetag(val) {
      console.log(val, "val");
      this.optionsData.forEach((elm, idx) => {
        if (val == 0) {
          this.selectedOptions = [];
          elm.check = false;
        } else {
          if (elm.value == val) {
            elm.check = false;
            let index = this.selectedOptions.getArrayIndex(elm.value);
            this.selectedOptions.splice(index, 1);
          }
        }
      });
      this.ruleForm.project_name = this.selected;
      let flag = false; //这儿
      this.$refs.ruleForm.validateField("project_name", (err) => {
        if (err) {
          flag = true;
        }
      });
      if (flag) {
        return;
      }
      console.log(this.selected, "remove");
      this.optionsAll = this.selectedOptions.length === this.optionsData.length;
    },
    handleChangeAelected() {
      console.log(this.selected, "change");
    },
    handleoptionsAllChange(isAll) {
      this.selectedOptions = [];
      if (isAll) {
        this.optionsData.forEach((elm, idx) => {
          elm.check = true;
          this.selectedOptions.push(elm.value);
        });
      } else {
        this.optionsData.forEach((elm, idx) => {
          elm.check = false;
        });
        this.selectedOptions = [];
      }
    },
    handleblurselected(e) {
      console.log(e);
      this.querysb.schedule_id = e.schedule_id;
      this.ruleForm.device = [];
      this.getDevice();
      // this.$refs.sel.$refs.input.blur = () => {
      //   this.ruleForm.xxxxx = this.selected;
      //   let flag = false; //这儿
      //   this.$refs.ruleForm.validateField("project_name", (err) => {
      //     if (err) {
      //       flag = true;
      //     }
      //   });
      //   if (flag) {
      //     return;
      //   }
      //   console.log(this.selected, "blur");
      //   console.log(this.ruleForm.xxxxx, " this.ruleForm.project_name blur");
      // };
      // this.ruleForm.xxxxx = this.selected;
    },
    handleTaskItemChange(item, items) {
      console.log(items, "items");
      // 这里是取出下标的方法，可以封装写出去
      Array.prototype.getArrayIndex = function (obj) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === obj) {
            return i;
          }
        }
        return -1;
      };
      if (!item.check) {
        items.forEach((elm, idx) => {
          if (item.device_group_no == elm.device_group_no) {
            let index = this.selectedOptions.getArrayIndex(
              item.device_type_name
            );
            this.selectedOptions.splice(index, 1);
          }
        });
      } else {
        items.forEach((elm, idx) => {
          if (item.device_group_no == elm.device_group_no) {
            this.selectedOptions.push(elm.device_group_no);
          }
        });
      }
      this.optionsAll = this.selectedOptions.length === items.length;
    },
    //搜索资源
    search(val) {
      this.queryres.name = val;
      this.getResources();
    },
    //搜索教案
    search1(val) {
      this.queryres.name = val;
      this.getResources();
    },
    //搜索资源包
    search2(val) {
      this.queryRespkgs.name = val;
      this.getResourcesPkgs();
    },
    input(val) {
      // console.log(val);
    },
    //切换引用资源
    changebabar(i) {
      this.zycomparison();
      this.$refs.searchs.clearvalue(); //清空搜索框内容
      this.active = i;
      this.page.pageNum = 1;
      this.$store.commit("sourceType", i + 1);
      if (i < 2) {
        this.queryres.type = i + 1;
        this.queryres.page = 1;
        this.queryres.name = "";
        this.getResources();
      }
      if (i == 2) {
        this.getResourcesPkgs();
      }
    },
    changenavbar(j) {
      this.active1 = j;
    },
    changenavbar1(j) {
      this.active2 = j;
      this.queryRespkgs.type = j + 1;
      this.getResourcesPkgs();
    },
    //打开el-dialog
    changevistats() {
      this.openpl = true;
    },
    //选择是否直播
    selectWhetherPusher(val) {
      console.log(val);
    },
    selectResource(v) {},
    Upload(file) {
      const that = this;
      let checkpoint; //断点续传的关键变量
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        console.log(getFileNameUUID());
        //正常来说data的参数应该后台返回，但是这里为了偷懒直接写死
        var dataObj = {
          accessKeyId: "LTAI5t6ddCtPkWmEvxLSLVpA",
          accessKeySecret: "gMGvJGbLv8EjXtgwiUFJG2RjlIlzT3",
          bucket: "sisheng-resource",
          region: "oss-cn-shenzhen",
        };
        that.fileInfo.filesize = file.file.size;
        console.log(file, "===========文件");
        // https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/
        // 支持断点续传，只尝试600次，
        // for (let i = 0; i < 600; i++) {
        try {
          const result = await client(dataObj).multipartUpload(
            `prod/match/elegant_moment/${fileName}`,
            file.file,
            {
              checkpoint,
              async progress(p, cpt) {
                // p进度条的值
                checkpoint = cpt;
                console.log(p, "p");
                console.log(cpt, "cpt");
                that.progress = Math.floor(p * 100);
              },
            }
          );
          that.fileInfo.path =
            "http://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/" +
            result.name;
          // break
        } catch (e) {
          setTimeout(function () {}, 1000);
          console.log(e);
        }
        // }
      }
      multipartUpload();
    },
    beforeAvatarUpload(file) {
      this.fileInfo.name = file.name;
      let index = file.name.lastIndexOf(".");
      this.fileInfo.mime = file.name.substring(index, file.length);
      console.log(this.fileInfo.mime, "this.fileInfo.mime");
      this.showProgress = true;
      if (this.fileInfo.mime == ".mp4") {
        this.fileInfo.file_type = 1;
      } else if (this.fileInfo.mime == ".png" || this.fileInfo.mime == ".jpg") {
        this.fileInfo.file_type = 2;
      } else if (
        this.fileInfo.mime == ".doc" ||
        this.fileInfo.mime == ".docx" ||
        this.fileInfo.mime == ".pdf"
      ) {
        this.fileInfo.file_type = 3;
      } else if (this.fileInfo.mime == ".mp3") {
        this.fileInfo.file_type = 4;
      } else {
      }
      console.log(this.fileInfo.file_type);
      // const isJPG =
      //   file.type === "image/jpeg" ||
      //   file.type === "image/png" ||
      //   file.type === "/docx" ||
      //   file.type === "video/mp4";
      // if (!isJPG) {
      //   this.load = false;
      //   this.$message.error("请上传mp4，jpg，png，jpeg,docx标准格式文件");
      // }
      // return isJPG;
    },
    logohandleRemove(file, fileList) {
      this.fileInfo = {};
      this.showProgress = false;
      this.progress = 0;
    },
    handleAvatarSuccess(file) {
      console.log(file, "file");
      let fileType = file.data[0].type;
      if (fileType == "video") {
        this.file_type = 1;
      } else {
        this.file_type = 2;
      }
      this.fileInfo = file.data[0];
    },
  },
};
</script>

<style lang="less" scoped>
.btnfrom {
  width: 100%;
  text-align: center;
  margin-top: 26px;
  display: flex;
  justify-content: center;

  .queren {
    margin: 0px 90px 0px 90px;
  }
}

/deep/.el-upload-dragger {
  width: 568px;
  height: 40px;
}

/deep/.el-upload-list__item is-ready {
  margin-top: 0px !important;
}

/deep/.el-upload-list el-upload-list--text {
  width: 552px;
}

.el-select {
  width: 664px;
}

.dialogbox {
  width: 100%;
  display: flex;

  .leftbox {
    // width: 791px;
    width: 50%;
    height: 550px;
    overflow-y: scroll;
    /deep/.el-form-item {
      // margin-bottom: 8px !important;
      // border: 1px solid;
    }
    .ziyuanlist {
      width: 564px;
    }

    .ziyuanlist:hover {
      background: rgb(221, 213, 213);
    }

    .inputs {
      width: 680px;
    }

    .weekbox {
      display: flex;
      justify-content: space-between;
      padding: 0px 75px 0px 0px;
      font-weight: bold;

      .weeks {
        width: 80px;
        height: 55px;
        border: 1px solid rgb(226, 216, 216);
        text-align: center;

        .riqi {
          position: relative;
          bottom: 20px;
        }
      }
    }

    .huixinimg {
      width: 30px;
      height: 30px;
      padding-top: 5px;
    }

    .tiishiwenzi {
      position: relative;
      bottom: 8px;
    }

    .Whichlesson {
      display: flex;
      justify-content: space-between;
      padding: 0px 75px 0px 0px;
      cursor: pointer;

      .lesson {
        text-align: center;
        width: 80px;
        border: 1px solid rgb(226, 216, 216);

        .banji {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .classsize {
            width: 75px;
          }

          .preparcolor {
            color: rgb(78, 190, 190);
          }

          .preparcolor1 {
            color: rgb(78, 190, 190);
            background: red;
          }
        }
      }
    }
  }

  .rightbox {
    width: 50%;
    height: 550px;

    .searchbox {
      display: flex;
      margin-top: 23px;
      justify-content: space-between;
    }

    .tabbar {
      display: flex;
      // position: absolute;
      // bottom: 930px;
      cursor: pointer;
      margin-bottom: 27px;
      background: #e1ecff;

      .active {
        color: #516cf7 !important;
        background: #fff;
      }

      .screen {
        margin-left: 21px;
        // font-size: 18px;
        padding: 0px 26px 0px 26px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        border-radius: 6px 6px 0px 0px;
        margin-right: 5px;
        color: rgb(0, 0, 0);
      }
    }

    .navbar {
      margin-right: 50px;
      // font-size: 18px;
      cursor: pointer;
      color: rgb(0, 0, 0);
    }

    .active1 {
      color: #516cf7;
    }
  }

  div::-webkit-scrollbar {
    width: 6px;
    /* 设置滚动条宽度 */
    height: 6px;
    /* 设置滚动条高度 */
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /* 设置滚动条滑块圆角 */
    background-color: rgba(0, 0, 0, 0.2);
    /* 设置滑块颜色 */
  }

  div::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
    /* 设置滑块轨道颜色 */
  }
}

.weekbox {
  display: flex;
  justify-content: space-between;
  padding: 0px 75px 0px 0px;

  .weeks {
    width: 137px;
    border: 1px solid rgb(226, 216, 216);
    text-align: center;
  }
}

/deep/ .kebiai {
  background: #ebbba7;
}
</style>
<style lang="less">
.last-check {
  li[aria-haspopup="true"] {
    .el-checkbox {
      display: none;
    }
  }
}
/deep/ .el-upload-list__item {
  .el-icon-close {
    color: #ccc !important;
  }
}
.el-icon-close {
  cursor: pointer;
}
</style>
